import { Button } from "react-bootstrap";
import styled from "styled-components";
import COLORS from "./colors";

export const CookButton = styled(Button)`
  height: 40px;
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  border: 2px solid green;
  outline: none;
  background-color: ${COLORS.GREEN_3};
  :hover {
    background-color: ${COLORS.GREEN_4};
  }
`;
