const COLORS = {
  GREEN_5: "#258d25",
  GREEN_4: "#30b530",
  GREEN_3: "#4acf4a",
  GREEN_2: "#5ed45e",
  GREEN_1: "#85e085",
  GREEN_0: "#c2efc2",
};

export default COLORS;
