import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Alert,
  InputGroup,
  Image,
} from "react-bootstrap";
import styled from "styled-components";

const RecipeEditor = (props: {
  show: boolean;
  onClose?: () => void;
  username: string;
  hash: string;
  img?: string;
  name?: string;
  description?: string;
  ingredientList?: string[];
  tagList?: string[];
}) => {
  const { username, hash, onClose } = props;
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    onClose?.();
    setShow(false);
  };

  const [newImage, setNewImage] = useState({ preview: "", raw: "" });
  const [newName, setNewName] = useState<string>("");
  const [newDescription, setNewDescription] = useState<string>("");
  const [newIngredient, setNewIngredient] = useState<string>("");
  const [newTag, setNewTag] = useState<string>("");
  const [newIngredientsList, setNewIngredientsList] = useState<string[]>([]);
  const [newTagsList, setNewTagsList] = useState<string[]>([]);

  /* Edit state is inferred by the presence of the name prop. If present we are editing and not inserting new */
  const [isEdit, setIsEdit] = useState(false);

  /* Initialise state fields from props */
  useEffect(() => {
    const { show, name, description, ingredientList, tagList } = props;
    setShow(show);

    /* Init form fields */
    name && setNewName(name);
    description && setNewDescription(description);
    ingredientList && setNewIngredientsList(ingredientList);
    tagList && setNewTagsList(tagList);
    name && setIsEdit(true);
  }, [props]);

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("onSubmit");
    console.log(newName);
    console.log(newDescription);
    console.log(newIngredientsList);
    console.log(newTagsList);

    if (newName === "") {
      setIsNameInvalid(true);
      return;
    }
    setIsNameInvalid(false);

    axios
      .post(`/api/updateRecipes/${username}/${hash}`, {
        title: newName,
        description: newDescription,
        img_path: "empty",
        ratings: [],
        insert_date: "06/06/2021",
        ingredients: newIngredientsList,
        tags: newTagsList,
      })
      .then((res) => {
        console.log("Post Success");
        console.log(res);

        handleClose();
      })
      .catch((err) => {
        console.log("Post Error");
        console.error(err);
      });
  };

  /* ************************************************************* */

  const addToIngredients = useCallback(
    (newElem: string) => {
      if (newElem) {
        newIngredientsList.push(newElem);
        setNewIngredientsList(newIngredientsList);
        setNewIngredient("");
      }
    },
    [newIngredientsList, setNewIngredientsList]
  );

  const handleIngredientEnter = (event: any) => {
    if (event.key === "Enter") {
      addToIngredients(newIngredient);
      event.preventDefault();
    }
  };

  /* ************************************************************* */

  const addToTags = useCallback(
    (newElem: string) => {
      if (newElem) {
        newTagsList.push(newElem);
        setNewTagsList(newTagsList);
        setNewTag("");
      }
    },
    [newTagsList, setNewTagsList]
  );

  const handleTagEnter = (event: any) => {
    if (event.key === "Enter") {
      addToTags(newTag);
      event.preventDefault();
    }
  };

  /* ************************************************************* */

  const handleChange = (e: any) => {
    if (e.target.files.length) {
      setNewImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={onFormSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEdit ? "Edit existing recipe" : "Add a new recipe"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Image Form Control */}
            <ImageContainer htmlFor="upload-button">
              {newImage.preview ? (
                <StyledImage src={newImage.preview} />
              ) : (
                <>
                  <MissingImage>Upload your photo</MissingImage>
                </>
              )}
            </ImageContainer>
            <input
              type="file"
              id="upload-button"
              style={{ display: "none" }}
              onChange={handleChange}
            />
            <br />
            {/* Name Form Control */}
            <Form.Control
              type="text"
              isInvalid={isNameInvalid}
              value={newName}
              placeholder="Name"
              onChange={({ target: { value } }: any) => setNewName(value)}
            />
            <br />

            {/* Description Form Control */}
            <Form.Control
              type="text"
              placeholder="Description"
              value={newDescription}
              onChange={({ target: { value } }: any) =>
                setNewDescription(value)
              }
            />

            {/* Ingredients Form Control */}
            <br />
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Ingredients"
                value={newIngredient}
                onKeyPress={handleIngredientEnter}
                onChange={({ target: { value } }: any) =>
                  setNewIngredient(value)
                }
              />
              <Button
                variant="secondary"
                onClick={() => {
                  addToIngredients(newIngredient);
                }}
              >
                Add
              </Button>
            </InputGroup>
            {renderItemList(newIngredientsList, setNewIngredientsList)}

            {/* Tags Form Control */}
            <br />
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Tags"
                value={newTag}
                onKeyPress={handleTagEnter}
                onChange={({ target: { value } }: any) => setNewTag(value)}
              />
              <Button
                variant="secondary"
                onClick={() => {
                  addToTags(newTag);
                }}
              >
                Add
              </Button>
            </InputGroup>
            {renderItemList(newTagsList, setNewTagsList)}
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const removeItemInList = (
  itemList: string[],
  idx: number,
  setter: (list: string[]) => void
) => {
  itemList.splice(idx, 1);
  setter(itemList.slice());
};

/* Render the nested items as tags */
const renderItemList = (
  itemList: string[],
  setter: (list: string[]) => void
) => {
  return (
    <Row>
      {itemList.map((item, idx) => (
        <Col xs={4}>
          <ListItem
            key={idx}
            variant={"secondary"}
            dismissible
            onClose={() => removeItemInList(itemList, idx, setter)}
          >
            {item}
          </ListItem>
        </Col>
      ))}
    </Row>
  );
};

const ListItem = styled(Alert)`
  height: 24px;
  padding: 0 10px 0 10px;
  margin: 5px 0 0 0;

  button {
    padding: 0 10px 0 0 !important;
  }
`;

const ImageContainer = styled.label`
  margin-left: 135px;
`;

const StyledImage = styled(Image)`
  width: 200px;
  height: 200px;
`;

const MissingImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  text-align: center;
  border: 3px solid gray;
`;

export default RecipeEditor;
