import React, { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import { CookButton } from "../../constants/components";
import RecipeEditor from "../RecipeEditor/RecipeEditor";

const Sidebar = (props: {
  username: string;
  hash: string;
  onNew: () => void;
}) => {
  const { username, hash, onNew } = props;
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const onNewWrapper = useCallback(() => {
    onNew();
    setShow(false);
  }, [onNew]);

  return (
    <>
      <StyledContainer>
        <Wrapper>
          <AddNewButton variant="success" onClick={handleShow}>
            Add New
          </AddNewButton>
        </Wrapper>
      </StyledContainer>
      <RecipeEditor
        show={show}
        onClose={onNewWrapper}
        username={username}
        hash={hash}
      />
    </>
  );
};

const StyledContainer = styled(Container)`
  background-color: ${COLORS.GREEN_1};
  height: 100vh;
  width: 100%;
`;

const Wrapper = styled.div`
  padding-top: 10px;
`;

const AddNewButton = styled(CookButton)`
  width: 100%;
`;

export default Sidebar;
