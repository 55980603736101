import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";

import Login from "../Login/Login";
import COLORS from "../../constants/colors";
import Dashboard from "../Dashboard/Dashboard";

function App() {
  const [username, setUsername] = useState<string>("");
  const [hash, setHash] = useState<string>("");

  return (
    <Router>
      <StyledAppContainer>
        <Switch>
          <Route path="/dashboard">
            <Dashboard username={username} hash={hash} />
          </Route>
          <Route>
            <Login setGlobalUsername={setUsername} setGlobalHash={setHash} />
          </Route>
        </Switch>
      </StyledAppContainer>
    </Router>
  );
}

const StyledAppContainer = styled.div`
  background-color: ${COLORS.GREEN_1};
`;

export default App;
