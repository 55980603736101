/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Card,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import COLORS from "../../constants/colors";
import app_logo from "../../assets/green-menu.svg";
import default_img from "../../assets/menu.svg";
import axios from "axios";
import RecipeEditor from "../RecipeEditor/RecipeEditor";

enum RecipeMode {
  ALL = "all",
  TAGS = "tags",
  TAG_SINGLE = "tag_single",
}

type Recipe = {
  title: string;
  img_path: string;
  img: string;
  description: string;
  ratings: {
    value: number;
    date: string;
  };
  insert_date: string;
  ingredients: string[];
  tags: string[];
};

function Dashboard(props: { username?: string; hash?: string }) {
  const [mode, setMode] = useState<RecipeMode>(RecipeMode.TAGS);
  const [recipeList, setRecipeList] = useState<Recipe[]>([]);

  /* Modal show */
  const [show, setShow] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe>();
  const selectRecipe = useCallback(
    (recipe: Recipe) => {
      setSelectedRecipe(recipe);
      setShow(true);
    },
    [setSelectedRecipe, setShow]
  );

  const refreshRecipes = useCallback(() => {
    axios
      .get(`/api/recipes/${props.username}/${props.hash}`)
      .then((res) => {
        console.log("Get Success: found recipes");
        console.log(res);

        const recipe_list: Recipe[] = res.data;
        for (const recipe of recipe_list) {
          recipe.img = default_img;
        }
        setRecipeList(recipe_list);
        setShow(false);
      })
      .catch((err) => {
        console.log("Post Error");
        console.error(err);
      });
  }, []);

  useEffect(() => {
    console.log(props.username);
    console.log(props.hash);
  }, []);

  /* On load get all recipes */
  useEffect(() => {
    refreshRecipes();
  }, [refreshRecipes]);

  const isAuthpresent = props.username && props.hash;

  if (!isAuthpresent) {
    return <Redirect to="/login" />;
  }

  const onBreadcrumb = (mode: RecipeMode) => {
    setMode(mode);
    console.log(mode);
  };

  return (
    <>
      <StyledContainer fluid>
        <Row>
          <NoPaddingFullColumn xs={3} xl={2} id="sidebar-wrapper">
            <Sidebar
              username={props.username!}
              hash={props.hash!}
              onNew={refreshRecipes}
            />
          </NoPaddingFullColumn>
          <CookbookColumn xs={9} xl={10} id="page-content-wrapper">
            <Container fluid>
              <Row>
                <HeaderCol xs={12}>
                  <DashboardLogoWrapper>
                    <DashboardLogo src={app_logo}></DashboardLogo>
                  </DashboardLogoWrapper>

                  <TitleWrapper>CookBook</TitleWrapper>
                </HeaderCol>
              </Row>
              <Row>
                <NoPaddingColumn xs={8}>
                  <StyledBreadcrumbs>
                    <StyledBreadcrumbItem
                      active={mode === RecipeMode.TAGS}
                      onClick={() => onBreadcrumb(RecipeMode.TAGS)}
                    >
                      Tags
                    </StyledBreadcrumbItem>
                    <StyledBreadcrumbItem
                      active
                      onClick={() => onBreadcrumb(RecipeMode.TAG_SINGLE)}
                    >
                      Category
                    </StyledBreadcrumbItem>
                  </StyledBreadcrumbs>
                </NoPaddingColumn>
                <NoPaddingColumn xs={4}>
                  <RightAlignedBreadcrumbs>
                    <StyledBreadcrumbItem
                      active={mode === RecipeMode.ALL}
                      onClick={() => onBreadcrumb(RecipeMode.ALL)}
                    >
                      All
                    </StyledBreadcrumbItem>
                  </RightAlignedBreadcrumbs>
                </NoPaddingColumn>
              </Row>
              <Row>{generateRecipeCards(recipeList, selectRecipe)}</Row>
            </Container>
          </CookbookColumn>
        </Row>
      </StyledContainer>
      <RecipeEditor
        show={show}
        onClose={refreshRecipes}
        username={props.username!}
        hash={props.hash!}
        name={selectedRecipe?.title}
        description={selectedRecipe?.description}
        tagList={selectedRecipe?.tags}
        ingredientList={selectedRecipe?.ingredients}
      />
    </>
  );
}

const generateRecipeCards = (
  recipeList: Recipe[],
  setSelectedRecipe: (recipe: Recipe) => void
) => {
  return recipeList.map((recipe, idx) => (
    <RecipeCol xs={12} lg={4} xl={2} key={idx}>
      <RecipeCard>
        <RecipeImage variant="top" src={recipe.img} />
        <RecipeBody>
          <RecipeTitle>{recipe.title}</RecipeTitle>
          <RecipeButtonGroup>
            <Button
              variant="secondary"
              onClick={() => setSelectedRecipe(recipe)}
            >
              Edit
            </Button>
            <Button variant="success">Open</Button>
          </RecipeButtonGroup>
        </RecipeBody>
      </RecipeCard>
    </RecipeCol>
  ));
};

const StyledContainer = styled(Container)`
  height: 100vh;
`;

const FullColumn = styled(Col)`
  height: 100vh;
`;

const NoPaddingFullColumn = styled(FullColumn)`
  padding-left: 0;
  padding-right: 0;
`;

const NoPaddingColumn = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`;

const CookbookColumn = styled(FullColumn)`
  background-color: ${COLORS.GREEN_0};
`;

/* ************************************************************* */

const HeaderCol = styled(NoPaddingColumn)`
  padding-top: 10px;
  border-bottom: 1px solid ${COLORS.GREEN_5};
`;

const DashboardLogo = styled.img`
  height: 40px;
  width: 40px;
`;

const DashboardLogoWrapper = styled.p`
  float: left;
  margin-bottom: 0;
`;

const TitleWrapper = styled.p`
  padding-left: 10px;
  font-size: 26px;
  padding-top: 5px;
  color: ${COLORS.GREEN_5};
  margin-bottom: 0;
`;

const StyledBreadcrumbs = styled(Breadcrumb)`
  > ol {
    background-color: transparent;
  }
  padding-left: 0;
`;

const RightAlignedBreadcrumbs = styled(StyledBreadcrumbs)`
  float: right;
`;

const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
  a {
    color: ${COLORS.GREEN_4};
    :hover {
      color: ${COLORS.GREEN_5};
    }
  }
`;

/* ************************************************************* */

const RecipeCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
`;

const RecipeCard = styled(Card)`
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid ${COLORS.GREEN_4};
  padding: 5px;
`;

const RecipeImage = styled(Card.Img)`
  height: 100px;
  width: 100px;
`;

const RecipeBody = styled(Card.Body)`
  margin-top: 5px;
  width: 100%;
  padding: 0;
`;

const RecipeTitle = styled(Card.Title)`
  width: 100%;
  color: white;
  margin-bottom: 5px;
  font-weight: 400;
  background-color: ${COLORS.GREEN_4};
`;

const RecipeButtonGroup = styled(ButtonGroup)`
  width: 100%;
  height: 24px;
  button {
    padding: 0;
  }
`;

/* ************************************************************* */

export default Dashboard;
