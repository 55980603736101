import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

import app_logo from "../../assets/menu.svg";
import { InputGroup, FormControl, Form, Alert } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import COLORS from "../../constants/colors";
import { CookButton } from "../../constants/components";

function Login(props: {
  setGlobalUsername: (username: string) => void;
  setGlobalHash: (hash: string) => void;
}) {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [alert, setAlert] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

  /* Login request against server */
  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    axios
      .post(`/api/login`, {
        username: username,
        password: password,
      })
      .then((res) => {
        const resData = res.data;
        console.log("Login Success");
        props.setGlobalUsername(resData.username);
        props.setGlobalHash(resData.hash);
        setAlert("success");
        setIsAuthenticated(true);
      })
      .catch((err) => {
        console.log("Login Error");
        console.log(err);
        setAlert("danger");
      });
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <LoginHeader>
      <LoginLogo src={app_logo} alt="logo" />
      <StyledTitle>CookBook</StyledTitle>
      <Form onSubmit={onFormSubmit}>
        <InputGroup>
          <StyledFormControl
            placeholder="username"
            onChange={({ target: { value } }: any) => setUsername(value)}
          />
        </InputGroup>
        <InputGroup>
          <StyledFormControl
            type="password"
            placeholder="password"
            onChange={({ target: { value } }: any) => setPassword(value)}
          />
        </InputGroup>
        {alert && (
          <StyledAlert variant={alert} dismissible>
            {alert === "success" ? "Login Successful" : "Login Failure"}
          </StyledAlert>
        )}
        <CookButton variant="success" type="submit">
          Login
        </CookButton>
      </Form>
    </LoginHeader>
  );
}

const StyledTitle = styled.p`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: calc(30px + 2vmin);
`;

const LoginHeader = styled.header`
  background-color: #85e085;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

const LoginLogo = styled.img`
  height: 20vmin;
  pointer-events: none;
`;

const StyledFormControl = styled(FormControl)`
  height: 30px;
  border-radius: 12px;
  padding: 5px;
  text-align: center;
  border: 2px solid green;
  outline: none;
  margin-bottom: 20px !important;
  background-color: ${COLORS.GREEN_0};
`;

const StyledAlert = styled(Alert)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export default Login;
